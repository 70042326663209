<template>
  <b-card
    v-if="data"
    no-body
    class="card-statistics"
  >
    <b-overlay
      :show="$apollo.loading"
      spinner-variant="primary"
    >
      <b-row>
        <b-col class="">
          <b-card-header>
            <b-card-title>
              Mentors
            </b-card-title>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="outline-primary"
              :to="{name: 'manage-mentors'}"
            >View Details
            </b-button>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-row>
              <b-col
                v-for="item in data.mentors"
                :key="item.icon"
                xl="6"
                sm="6"
                :class="item.customClass"
              >
                <b-media no-body>
                  <b-media-aside
                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      :variant="item.color"
                    >
                      <feather-icon
                        size="24"
                        :icon="item.icon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder">
                      {{ item.total }}
                    </h4>
                    <b-card-text class="mb-25">
                      {{ item.title }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
  BRow,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { getUserData } from '@/utils/auth'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BButton,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      data: {
        mentors: [
          {
            icon: 'UserPlusIcon',
            color: 'light-primary',
            title: 'In-House',
            total: null,
          },
          {
            icon: 'UsersIcon',
            color: 'light-secondary',
            title: 'Network',
            total: null,
          },
        ],
        partners: [
          {
            icon: 'UserPlusIcon',
            color: 'light-primary',
            title: 'In-House',
            total: null,
          },
          {
            icon: 'UsersIcon',
            color: 'light-secondary',
            title: 'Network',
            total: null,
          },
        ],
      },
    }
  },
  apollo: {
    programs: {
      query: gql`
        query dashboard_mentors($incubator_id: Int!) {
          users_associationtable(where: {incubator_id: {_eq: $incubator_id}, role: {_in: ["mentor", "partner"]}}) {
            role
            status
          }
        }
      `,
      variables: {
        incubator_id: getUserData()
          .associatedOrgDetails
          .filter(e => e.role === 'superadmin')[0].organization_id,
      },
      update(data) {
        const array = data.users_associationtable
        // Mentor
        this.data.mentors[0].total = array.filter(e => e.role.toLowerCase() === 'mentor' && e.status && e.status.toLowerCase() === 'accept').length
        this.data.mentors[1].total = array.filter(e => e.role.toLowerCase() === 'mentor').length - this.data.mentors[0].total
        // Partner
        this.data.partners[0].total = array.filter(e => e.role.toLowerCase() === 'partner' && e.status && e.status.toLowerCase() === 'accept').length
        this.data.partners[1].total = array.filter(e => e.role.toLowerCase() === 'partner').length - this.data.partners[0].total
      },
    },
  },
}
</script>
